body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {
  .mainLayout {
    @apply flex flex-col w-screen h-screen bg-yellow-600;
  }
  .rowLayout {
    @apply flex flex-row;
  }
  .columnLayout {
    @apply flex flex-col;
  }
  .bodyLayout {
    @apply flex flex-col h-[95%] w-full bg-orange-300;
  }
  .squareIcons {
    @apply h-[25px] w-[25px] object-contain;
  }
  .fontSizes {
    @apply 2xl:text-lg xl:text-lg lg:text-lg text-base;
  }
}
